import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetTransaction } from "../../../features/TransactionSlice";

import PageHeader from "layouts/inc/PageHeader";

import Helper, { convertDateFormat } from "services/helper";

import useTranslations from "../../../i18n/useTranslations";

const WarrantyDetail = () => {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateProfile = useSelector((state) => state.profile);
  const stateTransaction = useSelector((state) => state.transaction);

  const { t } = useTranslations(); // แปลภาษา

  const resetDataTransaction = () => {
    dispatch(resetTransaction()); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  /* Handle goback */
  const navigate = useNavigate();

  const handleGoBack = () => {
    resetDataTransaction();

    navigate(-1);
  };
  /* End Handle goback */

  useEffect(() => {
    // console.log("useEffect !", stateProfile, stateTransaction);

    if (stateProfile.lineUid === null || stateProfile.custNo === null) {
      navigate("/");
    }

    if (stateTransaction.id === 0 || stateTransaction.data.length === 9) {
      navigate("/warranty");
    }
  }, []);

  let _products = [],
    _productsPrice = 0;

  if (stateTransaction.data.products) {
    _products = JSON.parse(stateTransaction.data.products);

    for (let i = 0; i < _products.length; i++) {
      _productsPrice +=
        parseFloat(_products[i].price) *
        (_products[i].amount ? parseInt(_products[i].amount) : 1);
    }
    // console.log(_products);
  }

  return (
    <>
      <Helmet>
        <title>ห้างเพชรทองโกลด์เด้น 99 จำกัด</title>
        <meta name="title" content="ห้างเพชรทองโกลด์เด้น 99 จำกัด" />
        <meta
          name="description"
          content="ห้างเพชรทองโกลด์เด้น 99 จำกัด จำหน่ายสินค้าทุกหมวดหมู่ของเครื่องประดับ ทั้งในรูปแบบทองรูปพรรณ 96.5% ลวดลายสวยงาม ทองคำเปอร์เซ็นต์สูง  รับซื้อและจำนำตามราคาสมาคม..."
        />
        <meta name="keyword" content="" />
        <meta property="og:title" content="ห้างเพชรทองโกลด์เด้น 99 จำกัด" />
        <meta
          property="og:description"
          content="ห้างเพชรทองโกลด์เด้น 99 จำกัด จำหน่ายสินค้าทุกหมวดหมู่ของเครื่องประดับ ทั้งในรูปแบบทองรูปพรรณ 96.5% ลวดลายสวยงาม ทองคำเปอร์เซ็นต์สูง  รับซื้อและจำนำตามราคาสมาคม..."
        />
        <meta property="og:image" content="" />
        <meta property="og:url" content="assets/img/share/share-cover.jpg" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="GOLDEN99" />
      </Helmet>

      <div id="pageWarranty-Details">
        <PageHeader />

        <main>
          <div className="wrapContent">
            <section id="pWarrantyDetailsMain">
              <div className="bWarrantyDetails">
                <div className="bHead">
                  <div className="bGroup">
                    <div className="bRow">
                      <h1>
                        {stateTransaction.data.buyNo
                          ? stateTransaction.data.buyNo
                          : stateTransaction.data.changeNo}
                      </h1>
                      <p>
                        {convertDateFormat(
                          stateTransaction.data.createdDate,
                          "d/m/y"
                        )}
                      </p>
                    </div>
                    <div className="bRow">
                      <p>{t.warrantyDetailPage.detailType} :</p>
                      <p>
                        {
                          t.warrantyDetailPage[
                            `detailType${stateTransaction.data.type}`
                          ]
                        }
                      </p>
                    </div>
                    <div className="bRow">
                      <p>{t.warrantyDetailPage.detailBranch} :</p>
                      <p>{stateTransaction.data.branch}</p>
                    </div>
                  </div>
                </div>
                <div className="bDetails">
                  {_products.map(({ name, price, amount }, index) => (
                    <div className="bGroup" key={index + 1}>
                      <div className="bRow">
                        <p>{t.warrantyDetailPage.detailProductType} :</p>
                        <p>{name}</p>
                      </div>
                      <div className="bRow">
                        <p>{t.warrantyDetailPage.detailProductPrice} :</p>
                        <p>฿{parseFloat(price).toLocaleString()}</p>
                      </div>
                      <div className="bRow">
                        <p>{t.warrantyDetailPage.detailProductAmount} :</p>
                        <p>{amount}</p>
                      </div>
                    </div>
                  ))}
                  <div className="bGroup total">
                    <div className="bRow">
                      <p>{t.warrantyDetailPage.detailTotal}</p>
                      <p>฿{parseFloat(_productsPrice).toLocaleString()}</p>
                    </div>
                    <div className="bRow">
                      <p>{t.warrantyDetailPage.detailPaid}</p>
                      <p>
                        {stateTransaction.data.paidPrice &&
                        stateTransaction.data.paidPrice !== "0"
                          ? "฿" +
                            parseFloat(
                              stateTransaction.data.paidPrice
                            ).toLocaleString()
                          : "฿0"}
                      </p>
                    </div>
                    <div className="bRow">
                      <p>{t.warrantyDetailPage.detailReturn}</p>
                      <p>
                        {stateTransaction.data.returnPrice &&
                        stateTransaction.data.returnPrice !== "0"
                          ? "฿" +
                            parseFloat(
                              stateTransaction.data.returnPrice
                            ).toLocaleString()
                          : "฿0"}
                      </p>
                    </div>
                    <div className="bRow">
                      <p>{t.warrantyDetailPage.detailDiscount}</p>
                      <p>
                        {stateTransaction.data.discount &&
                        stateTransaction.data.discount !== "0"
                          ? "- ฿" +
                            parseFloat(
                              stateTransaction.data.discount
                            ).toLocaleString()
                          : "฿0"}
                      </p>
                    </div>
                    <div className="bRow">
                      <p>{t.warrantyDetailPage.detailGrandTotal}</p>
                      {/* <p>฿{Helper.FN.toNumberWithCommas(stateTransaction.data.grand_total)}</p> */}
                      <p>
                        ฿
                        {parseFloat(
                          stateTransaction.data.grandTotal
                        ).toLocaleString()}
                      </p>
                    </div>
                  </div>
                  <div className="bGroup point">
                    <div className="bRow">
                      <p>{t.warrantyDetailPage.detailPoints}</p>
                      <p>
                        {`${parseInt(
                          stateTransaction.data.sbPoint
                        ).toLocaleString()} ${
                          t.warrantyDetailPage.detailPoint
                        }`}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id="pActionBtn">
              <div className="bBtn">
                <button className="btn outline" onClick={handleGoBack}>
                  {t.btn.back}
                </button>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default WarrantyDetail;
